<template>
  <div class="section-three box-height-wrap">
    <div class="programme-wrap">
      <h2>云昇新控-方案中心</h2>
      <div class="plan-box">
        <swiper :options="swiperOption"
                ref="mySwiper">
          <swiper-slide>
            <div class="plan-item" @click="handleGo('/programme/water')">
              <div class="pic-box pl-zhsw"></div>
              <div class="txt-box">
                <h4>智慧水务</h4>
                <div class="txt">
                  实现生产数字化、管理协同化、决策科学化、服务主动化，使水厂信息化水平保持在行业领先水平。
                </div>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="plan-item" @click="handleGo('/programme/integrate')">
              <div class="pic-box pl-xtjc"></div>
              <div class="txt-box">
                <h4>系统集成</h4>
                <div class="txt">
                  为提高水质，保障供水，优化成本， 改善服务提供有效的技术手段，创造最好的经济和社会效益。
                </div>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="plan-item" @click="handleGo('/programme/factory')">
              <div class="pic-box pl-zhgc"></div>
              <div class="txt-box">
                <h4>智慧工厂</h4>
                <div class="txt">
                  数字化生产运营，实现均衡生产和数字化精益制造，助力传统工厂转型。
                </div>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="plan-item" @click="handleGo('/programme/lighting')">
              <div class="pic-box pl-zhzm"></div>
              <div class="txt-box">
                <h4>智慧照明</h4>
                <div class="txt">
                  利用物联网控制系统，多协议兼容，低成本维护，完美满足绿色建筑的设备管理及能源管理要求。
                </div>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="plan-item" @click="handleGo('/programme/pipe')">
              <div class="pic-box pl-zhgl"></div>
              <div class="txt-box">
                <h4>智慧管廊</h4>
                <div class="txt">
                  解决城市地下管廊泄漏、爆管等危险，保障地下综合管廊的安全传输，降低企业运营风险。
                </div>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="plan-item" @click="handleGo('/programme/farm')">
              <div class="pic-box pl-zhny"></div>
              <div class="txt-box">
                <h4>智慧农业</h4>
                <div class="txt">
                  实现农业信息的快速准确获取。为作物播种、施肥、收获活动提供决策支持。
                </div>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="plan-item" @click="handleGo('/programme/irrigation')">
              <div class="pic-box pl-zhgq"></div>
              <div class="txt-box">
                <h4>智慧灌区</h4>
                <div class="txt">
                  优化水资源，多元化采集，结合移动端APP构建了一套完善的灌溉工程体系，解决灌区相关痛点。
                </div>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="plan-item" @click="handleGo('/programme/fire')">
              <div class="pic-box pl-zhxf"></div>
              <div class="txt-box">
                <h4>智慧消防</h4>
                <div class="txt">
                  实现对火灾防控的自动化管理，对灭火救援的智能化管理，对管理运营的系统化管理。
                </div>
              </div>
            </div>
          </swiper-slide>
          <div class="swiper-button-prev" slot="button-prev"></div>
          <div class="swiper-button-next" slot="button-next"></div>
        </swiper>
      </div>
    </div>
  </div>
</template>
<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
  name: 'section_programme',
  components: {
    Swiper,
    SwiperSlide
  },
  props: {
    isCurrent: {
      type: Number,
      default: 2
    }
  },
  data () {
    return {
      swiperOption: {
        autoplay: {
          delay: 5000,
          disableOnInteraction: false
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        slidesPerView: 'auto',
        // slidesPerView: 5, // 一行显示*个
        spaceBetween: 0, // 间隔30
        speed: 1000, // 滑动速度
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      }
    }
  },
  computed: {
    swiper () {
      return this.$refs.mySwiper.$swiper
    }
  },
  methods: {
    handleGo (way) {
      this.$router.push({ path: way })
    }
  }
}
</script>
<style lang="less" scoped>
.section-three {
  width: 100%;
  background: url("../../../assets/img/home/programme_bg.jpg") no-repeat center bottom;
  background-size: cover;
}

.programme-wrap {
  width: 100%;
  padding-top: 90px;

  h2 {
    font-size: 42px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #F0F0F0;
    padding: 4% 0;
  }
}

.plan-box {
  width: 90%;
  margin: 0 auto;
  text-align: center;

  .plan-item {
    width: 286px;
    height: auto;
    display: inline-block;

    .pic-box {
      width: 286px;
      height: 286px;
      border-radius: 99%;
      border: 3px solid #ffffff;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }

    .pl-zhgq {
      background-image: url("../../../assets/img/home/pl_zhgq.jpg");
    }

    .pl-xtjc {
      background-image: url("../../../assets/img/home/pl_xtjc.jpg");
    }

    .pl-zhsw {
      background-image: url("../../../assets/img/home/pl_zhsw.jpg");
    }

    .pl-zhgc {
      background-image: url("../../../assets/img/home/pl_zhgc.jpg");
    }

    .pl-zhgl {
      background-image: url("../../../assets/img/home/pl_zhgl.jpg");
    }

    .pl-zhzm {
      background-image: url("../../../assets/img/home/pl_zhzm.jpg");
    }

    .pl-zhny {
      background-image: url("../../../assets/img/home/pl_zhny.jpg");
    }

    .pl-zhxf {
      background-image: url("../../../assets/img/home/pl_zhxf.jpg");
    }

    .txt-box {
      width: 100%;
      height: auto;
      color: #F0F0F0;
      font-size: 18px;
      line-height: 34px;

      h4 {
        font-size: 30px;
        padding: 8% 0;
      }
    }

  }
}

</style>
<style lang="less">

.plan-box {
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    width: 20%;
  }
}

@media (max-width: 1600px) {
  .plan-box {
    .swiper-slide {
      width: 30%;
    }
  }
}

@media (max-width: 1100px) {
  .plan-box {
    .swiper-slide {
      width: 50%;
    }
  }
}

@media (max-width: 650px) {
  .plan-box {
    .swiper-slide {
      width: 100%;
    }
  }
}
</style>
