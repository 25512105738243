<template>
  <div class="section-six">
    <div class="footer-box">
      <Footer></Footer>
    </div>
  </div>
</template>

<script>
import Footer from '../../../components/Footer.vue'

export default {
  name: 'footer-wrap',
  components: { Footer },
  props: {
    isCurrent: {
      type: Number,
      default: 5
    }
  },
  data () {
    return {}
  },
  computed: {}
}
</script>

<style lang="less" scoped>

.section-six {
  width: 100%;
  height: 100%;
}

.footer-box {
  width: 100%;
}
</style>
