<template>
  <div class="section-two box-height-wrap">
    <div class="product-wrap">
      <h2 class="animate__animated" :class="[isCurrent==1?'animate__fadeInDown':'']">云昇新控-产品中心</h2>
      <div class="product-box">
        <div class="big-box animate__animated animate__delay-1s" :class="[isCurrent==1?'animate__fadeInRight':'']">
          <el-row :gutter="10">
            <el-col :xs="12" :sm="12" :md="6" :lg="6" :xl="6">
              <div class="big-item" @click="handleGo('/product/ggd')">
                <div class="img-box">
                  <img src="../../../assets/img/home/cp_pdg.png" class="image">
                </div>
                <div class="info-box">
                  <h5>高低压配电柜</h5>
                  <div class="txt">运作更安全可靠 具有更人性化的人机界面 防爆操作</div>
                </div>
              </div>
            </el-col>
            <el-col :xs="12" :sm="12" :md="6" :lg="6" :xl="6">
              <div class="big-item" @click="handleGo('/product/water')">
                <div class="img-box">
                  <img src="../../../assets/img/home/cp_sw.png" class="image">
                </div>
                <div class="info-box">
                  <h5>智慧水务</h5>
                  <div class="txt">SCADA/DMA/PIS/OMS/EMS/SMP/SCS</div>
                </div>
              </div>
            </el-col>
            <el-col :xs="12" :sm="12" :md="6" :lg="6" :xl="6">
              <div class="big-item" @click="handleGo('/programme/integrate')">
                <div class="img-box">
                  <img src="../../../assets/img/home/cp_zdh.png" class="image">
                </div>
                <div class="info-box">
                  <h5>自动化系统集成</h5>
                  <div class="txt">实现水务业务系统的控制智能化/数据资源化/管理精确化/决策智慧化 保障水务设施安全运行</div>
                </div>
              </div>
            </el-col>
            <el-col :xs="12" :sm="12" :md="6" :lg="6" :xl="6">
              <div class="big-item" @click="handleGo('/product/factory')">
                <div class="img-box">
                  <img src="../../../assets/img/home/cp_gc.png" class="image">
                </div>
                <div class="info-box">
                  <h5>智慧工厂</h5>
                  <div class="txt">WMS/QMS/PLM/MES/Andon/BOM/DMS</div>
                </div>
              </div>
            </el-col>
          </el-row>
          <div></div>
        </div>
        <div class="big-box small-box animate__animated animate__delay-2s"
             :class="[isCurrent==1?'animate__fadeInLeft':'']">
          <el-row :gutter="10">
            <el-col :xs="8" :sm="8" :md="8" :lg="4" :xl="4">
              <div class="big-item" @click="handleGo('/product/lighting')">
                <div class="img-box">
                  <i class="iconfont icon-zhzm"></i>
                </div>
                <div class="info-box">
                  <h6>智慧照明</h6>
                  <div class="txt">延续灯光璀璨，塑造美好未来。</div>
                </div>
              </div>
            </el-col>
            <el-col :xs="8" :sm="8" :md="8" :lg="4" :xl="4">
              <div class="big-item" @click="handleGo('/product/instrument')">
                <div class="img-box">
                  <i class="iconfont icon-yibiao"></i>
                </div>
                <div class="info-box">
                  <h6>仪器仪表</h6>
                  <div class="txt">品种繁多、科学智能</div>
                </div>
              </div>
            </el-col>
            <el-col :xs="8" :sm="8" :md="8" :lg="4" :xl="4">
              <div class="big-item" @click="handleGo('/programme/irrigation')">
                <div class="img-box">
                  <i class="iconfont icon-guanqu"></i>
                </div>
                <div class="info-box">
                  <h6>智慧灌区</h6>
                  <div class="txt">物联采集、实时监控</div>
                </div>
              </div>
            </el-col>
            <el-col :xs="8" :sm="8" :md="8" :lg="4" :xl="4">
              <div class="big-item" @click="handleGo('/product/pipe')">
                <div class="img-box">
                  <i class="iconfont icon-gljc"></i>
                </div>
                <div class="info-box">
                  <h6>智慧管廊</h6>
                  <div class="txt">全面监测、远程控制</div>
                </div>
              </div>
            </el-col>
            <el-col :xs="8" :sm="8" :md="8" :lg="4" :xl="4">
              <div class="big-item" @click="handleGo('/product/fire')">
                <div class="img-box">
                  <i class="iconfont icon-xf"></i>
                </div>
                <div class="info-box">
                  <h6>智慧消防</h6>
                  <div class="txt">智能防控、智慧管理</div>
                </div>
              </div>
            </el-col>
            <el-col :xs="8" :sm="8" :md="8" :lg="4" :xl="4">
              <div class="big-item" @click="handleGo('/product/farm')">
                <div class="img-box">
                  <i class="iconfont icon-nypt"></i>
                </div>
                <div class="info-box">
                  <h6>智慧农业</h6>
                  <div class="txt">科学分析、动态模拟</div>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'section_product',
  components: {},
  props: {
    isCurrent: {
      type: Number,
      default: 1
    }
  },
  data () {
    return {}
  },
  methods: {
    handleGo (way) {
      this.$router.push({ path: way })
    }
  }
}
</script>
<style lang="less" scoped>

.section-two {
  width: 100%;
  background: url("../../../assets/img/home/product_bg.jpg") no-repeat center bottom;
  background-size: cover;
}

.product-wrap {
  width: 100%;
  padding-top: 1%;

  h2 {
    font-size: 48px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #434343;
    padding-top: 3%;
  }

  .product-box {
    width: 84%;
    margin: 0 auto;
    height: 100%;

    .big-box {
      width: 100%;
      height: auto;

      .big-item {
        margin: 10% 2% 0;
        height: 320px;
        background-color: #ffffff;
        overflow: hidden;
        cursor: pointer;

        .img-box {
          width: 100%;
          height: 64%;
          overflow: hidden;

          img {
            width: 100%;
            max-height: 100%;
          }
        }

        .info-box {
          padding: 2% 5%;
          height: 36%;
          overflow: hidden;
          text-align: left;

          h5 {
            font-size: 24px;
            padding-bottom: 2%;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #1890FF;
          }

          .txt {
            width: 100%;
            min-height: 70px;
            overflow: hidden;
            font-size: 14px;
            font-family: SimSun;
            font-weight: 400;
            color: #8C8C8C;
          }
        }
      }

      .big-item:hover {
        background-color: #1890FF;

        .img-box {
          img {
            transform: scale(1.2);
          }

          i {
            color: #ffffff;
          }
        }

        .info-box {
          h5, h6, .txt {
            color: #ffffff;
          }
        }
      }
    }

    .small-box {
      .big-item {
        margin: 15% 5% 0;
        height: 250px;

        .img-box {
          padding-top: 5%;
          height: 54%;

          i {
            font-size: 90px;
            color: #1890FF;
            position: relative;
            top: 10%;
          }
        }

        .info-box {
          text-align: center;

          h6 {
            margin-bottom: 6%;
            color: #434343;
            font-size: 24px;
            font-weight: bold;
          }

          .txt {
            color: #999999;
          }
        }

      }
    }
  }
}

@media (max-width: 1200px) {
  .product-wrap {
    h2 {
      font-size: 38px;
    }

    .product-box {
      width: 90%;

      .big-box {

        .big-item {
          width: 100%;
          height: 280px;

          .img-box {
            width: 100%;
            height: 58%;
          }

          .info-box {
            height: 42%;

            h5 {
              font-size: 20px;
              padding: 2% 0;
            }
          }
        }
      }

      .small-box {
        .big-item {
          height: 200px;

          .img-box {
            height: 46%;

            i {
              font-size: 70px;
              color: #1890FF;
              position: relative;
              top: 10%;
            }
          }

          .info-box {
            h6 {
              font-size: 20px;
            }
          }

        }
      }
    }
  }
}

@media (max-width: 768px) {

  .section-two{
    height: 880px;
  }

  .product-wrap {
    h2 {
      font-size: 28px;
    }

    .product-box {
      .big-box {
        .big-item {
          width: 100%;
          height: 230px;

          .img-box {
            height: 50%;
          }
        }
      }

      .small-box {
        .big-item {
          height: 130px;

          .img-box {
            height: 38%;

            i {
              font-size: 40px;
            }
          }

          .info-box {
            height: 52%;

            h6 {
              font-size: 16px;
            }

            .txt {
              font-size: 12px;
            }
          }

        }
      }
    }
  }
}

//@media (max-height: 900px) {
//  .product-wrap {
//    .product-box {
//      .big-box {
//        .big-item {
//          width: 100%;
//          height: 260px;
//        }
//      }
//
//      .small-box {
//        .big-item {
//          width: 100%;
//          height: 200px;
//        }
//      }
//    }
//  }
//}

</style>
