<template>
  <div class="banner-one">
    <div ref="oneTxt" class="txt animate__animated" :class="[isOn==0?'animate__fadeInUp':'']">
      <img src="../../../../assets/img/home/one_txt.png" alt="云昇新控官网">
    </div>
  </div>
</template>

<script>

export default {
  name: 'banner_one',
  components: {},
  props: {
    isOn: {
      type: Number,
      default: 0
    }
  }
}
</script>

<style scoped lang="less">
.banner-one {
  width: 100%;
  height: 100%;
  background: url("../../../../assets/img/home/banner1.png") no-repeat center;
  background-size: cover;

  .txt {
    width: 32%;
    height: 16%;
    position: relative;
    top: 34%;
    left: 10%;

    img {
      max-width: 100%;
      height: auto;
    }
  }
}

@media (max-width: 768px) {
  // 变成手机模式
  .banner-one {
    background: url("../../../../assets/img/home/phone_banner1.png") no-repeat center;
    background-size: cover;

    .txt {
      width: 80%;
      height: 16%;
      position: relative;
      top: 20%;
      left: 0;
      margin: 0 auto;
    }
  }
}

</style>
