<template>
  <div class="section-one box-height-wrap">
    <!--  :interval="4000"  autoplay-->
    <el-carousel ref="carousel" @mouseenter.native="delHandleMouseEnter" :autoplay="false"
                 @change="bannerChange">
      <el-carousel-item>
        <Banner1 :isOn="current"></Banner1>
      </el-carousel-item>
      <el-carousel-item>
        <Banner2 :isOn="current"></Banner2>
      </el-carousel-item>
      <el-carousel-item>
        <Banner4 :isOn="current"></Banner4>
      </el-carousel-item>
      <el-carousel-item>
        <Banner3 :isOn="current"></Banner3>
      </el-carousel-item>
      <el-carousel-item>
        <Banner5 :isOn="current"></Banner5>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>
<script>
import Banner1 from './banner/One.vue'
import Banner2 from './banner/Two.vue'
import Banner3 from './banner/Three.vue'
import Banner4 from './banner/Four.vue'
import Banner5 from './banner/Five.vue'

export default {
  name: '',
  components: {
    Banner1,
    Banner2,
    Banner3,
    Banner4,
    Banner5
  },
  data () {
    return {
      current: 0
    }
  },
  methods: {
    delHandleMouseEnter () {
      this.$refs.carousel.handleMouseEnter = () => {
      }
    },
    bannerChange (index) {
      this.current = index
    }
  }
}
</script>
<style lang="less">
.section-one {
  width: 100%;

  .el-carousel {
    width: 100%;
    height: 100%;
  }

  .el-carousel__container {
    width: 100%;
    height: 100%;
  }

  .el-carousel__item {
    width: 100%;
    height: 100%;
  }
}
</style>
