<template>
  <div class="banner-two">
    <div class="two-pic animate__animated" :class="[isOn==1?'animate__zoomIn':'']">
      <img src="../../../../assets/img/home/two_pic.png" alt="云昇新控官网">
    </div>
  </div>
</template>

<script>

export default {
  name: 'banner_one',
  components: {},
  props: {
    isOn: {
      type: Number
    }
  }
}
</script>

<style scoped lang="less">
.banner-two {
  width: 100%;
  height: 100%;
  background: url("../../../../assets/img/home/banner2.jpg") no-repeat center;
  background-size: cover;

  .two-pic {
    width: 80%;
    height: 86%;
    //background: url("../../../../assets/img/home/two_pic.png") no-repeat center;
    //background-size: contain;
    position: relative;
    top: 10%;
    left: 9%;

    img {
      max-width: 100%;
      height: auto;
    }
  }
}

@media (max-width: 768px) {
  // 变成手机模式
  .banner-two {
    background: url("../../../../assets/img/home/phone_banner_2.jpg") no-repeat center;
    background-size: cover;

    .two-pic {
      width: 80%;
      height: 88%;
      position: relative;
      top: 20%;
      left: 10%;
    }
  }
}

</style>
