import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: {
      title: '云昇新控-重庆云昇新控智能科技有限公司'
    },
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    meta: {
      title: '云昇新控-关于我们'
    },
    component: () => import('../views/About.vue')
  },
  // 产品
  {
    path: '/product/water',
    name: 'productWater',
    meta: {
      title: '云昇新控-云昇新控-产品-智慧水务'
    },
    component: () => import('../views/product/Water.vue')
  },
  {
    path: '/product/ggd',
    name: 'productGGD',
    meta: {
      title: '云昇新控-产品-高低压配电柜'
    },
    component: () => import('../views/product/GGD.vue')
  },
  {
    path: '/product/integrate',
    name: 'productIntegrate',
    meta: {
      title: '云昇新控-产品-自动化系统集成'
    },
    component: () => import('../views/product/Integrate.vue')
  },
  {
    path: '/product/instrument',
    name: 'productInstrument',
    meta: {
      title: '云昇新控-产品-仪器仪表'
    },
    component: () => import('../views/product/Instrument.vue')
  },
  {
    path: '/product/irrigation',
    name: 'productIrrigation',
    meta: {
      title: '云昇新控-产品-智慧灌区'
    },
    component: () => import('../views/product/Irrigation.vue')
  },
  {
    path: '/product/lighting',
    name: 'productLighting',
    meta: {
      title: '云昇新控-产品-智慧照明'
    },
    component: () => import('../views/product/Lighting.vue')
  },
  {
    path: '/product/farm',
    name: 'productFarm',
    meta: {
      title: '云昇新控-产品-智慧农业'
    },
    component: () => import('../views/product/Farm.vue')
  },
  {
    path: '/product/fire',
    name: 'productFire',
    meta: {
      title: '云昇新控-产品-智慧消防'
    },
    component: () => import('../views/product/Fire.vue')
  },
  {
    path: '/product/pipe',
    name: 'productPipe',
    meta: {
      title: '云昇新控-产品-智慧管廊'
    },
    component: () => import('../views/product/Pipe.vue')
  },
  {
    path: '/product/factory',
    name: 'productFactory',
    meta: {
      title: '云昇新控-产品-智慧工厂'
    },
    component: () => import('../views/product/Factory.vue')
  },
  // 方案
  {
    path: '/programme/water',
    name: 'programmeWater',
    meta: {
      title: '云昇新控-方案-智慧水务'
    },
    component: () => import('../views/programme/Water.vue')
  },
  {
    path: '/programme/pipe',
    name: 'programmePipe',
    meta: {
      title: '云昇新控-方案-智慧管廊'
    },
    component: () => import('../views/programme/Pipe.vue')
  },
  {
    path: '/programme/fire',
    name: 'programmeFire',
    meta: {
      title: '云昇新控-方案-智慧消防'
    },
    component: () => import('../views/programme/Fire.vue')
  },
  {
    path: '/programme/farm',
    name: 'programmeFarm',
    meta: {
      title: '云昇新控-方案-智慧农业'
    },
    component: () => import('../views/programme/Farm.vue')
  },
  {
    path: '/programme/lighting',
    name: 'programmeLighting',
    meta: {
      title: '云昇新控-方案-智慧照明'
    },
    component: () => import('../views/programme/Lighting.vue')
  },
  {
    path: '/programme/irrigation',
    name: 'programmeIrrigation',
    meta: {
      title: '云昇新控-方案-智慧灌区'
    },
    component: () => import('../views/programme/Irrigation.vue')
  },
  {
    path: '/programme/integrate',
    name: 'programmeIntegrate',
    meta: {
      title: '云昇新控-方案-自动化系统集成'
    },
    component: () => import('../views/programme/Integrate.vue')
  },
  {
    path: '/programme/factory',
    name: 'programmeFactory',
    meta: {
      title: '云昇新控-方案-智慧工厂'
    },
    component: () => import('../views/programme/Factory.vue')
  },
  // 服务
  {
    path: '/service/after-sales',
    name: 'ServiceAfterSales',
    meta: {
      title: '云昇新控-售后服务'
    },
    component: () => import('../views/service/AfterSales.vue')
  },
  {
    path: '/service/message',
    name: 'ServiceMessage',
    meta: {
      title: '云昇新控-留言'
    },
    component: () => import('../views/service/Message.vue')
  },
  // 案例
  {
    path: '/case',
    name: 'Case',
    meta: {
      title: '云昇新控-案例中心'
    },
    component: () => import('../views/case/Case.vue')
  },
  {
    path: '/case-a',
    name: 'CaseA',
    meta: {
      title: '云昇新控-精品案例-污水处理'
    },
    component: () => import('../views/case/CaseA.vue')
  },
  {
    path: '/case-b',
    name: 'CaseB',
    meta: {
      title: '云昇新控-精品案例-智慧水务'
    },
    component: () => import('../views/case/CaseB.vue')
  },
  {
    path: '/case-c',
    name: 'CaseC',
    meta: {
      title: '云昇新控-精品案例-数字化工厂'
    },
    component: () => import('../views/case/CaseC.vue')
  },
  // 文章
  {
    path: '/article/privacy-security',
    name: 'PrivacySecurity',
    meta: {
      title: '云昇新控-隐私与安全'
    },
    component: () => import('../views/article/PrivacySecurity.vue')
  },
  {
    path: '/article/legal-statement',
    name: 'LegalStatement',
    meta: {
      title: '云昇新控-法律声明'
    },
    component: () => import('../views/article/LegalStatement.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
