<template>
  <div class="section-four">
    <div class="empty-nav"></div>
    <div class="white-bg animate__animated" :class="[isCurrent==3?'animate__fadeIn':'']"></div>
    <div class="about-wrap">
      <div class="txt-wrap">
        <h2 class="animate__animated" :class="[isCurrent==3?'animate__bounceInLeft':'']">关于云昇新控</h2>
        <p class="animate__animated" :class="[isCurrent==3?'animate__lightSpeedInLeft':'']">ABOUT</p>
        <p class="animate__animated" :class="[isCurrent==3?'animate__fadeInLeftBig':'']">YUNSHENG</p>
      </div>
      <div class="info-wrap">
        <div class="txt animate__animated animate__delay-1s" :class="[isCurrent==3?'animate__fadeIn':'']">
          <p>重庆云昇新控智能科技有限公司成立于2020年，总部位于重庆，在江津珞璜建有独立工厂，并在成都、西安等地设有办事处。</p>
          <p>
            早在公司成立之初，主创人员就已深入了解整个市场，从事相关行业工作近二十年，掌握了丰富的市场信息，积累了大量的行业经验，所以，公司虽是行业新秀，也已拿下多个品牌项目，保质保量推进项目开展，赢得业主赞誉。现已成为西南地区该产业链中具有影响力的品牌之一，服务辐射重庆、四川、西安等区域，涉及100多个区县，遥遥领先西南地区其他产业。</p>
          <p>公司主要从事软件开发、电气自动化系统集成及技术开发、计算机软硬件研发。为此广招贤士，打造了一支技术精湛、配合默契的精良团队，依托雄厚资源，凭借团队的努力，争取每一步都走得更快更好更高效。</p>
        </div>
        <div class="icon-box">
          <div @click="handleGo" class="icon-item animate__animated animate__delay-1s"
               :class="[isCurrent==3?'animate__zoomIn':'']">
            <div class="item-img"><i class="iconfont icon-gsjj"></i></div>
            <p>企业简介</p>
          </div>
          <div @click="handleGo" class="icon-item animate__animated animate__delay-2s"
               :class="[isCurrent==3?'animate__zoomIn':'']">
            <div class="item-img"><i class="iconfont icon-qywh"></i></div>
            <p>企业文化</p>
          </div>
          <div @click="handleGo" class="icon-item animate__animated animate__delay-3s"
               :class="[isCurrent==3?'animate__zoomIn':'']">
            <div class="item-img"><i class="iconfont icon-help"></i></div>
            <p>联系我们</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'section_about',
  components: {},
  props: {
    isCurrent: {
      type: Number,
      default: 3
    }
  },
  data () {
    return {}
  },
  computed: {},
  methods: {
    handleGo () {
      this.$router.push({ path: '/about' })
    }
  }

}
</script>

<style lang="less" scoped>
.section-four {
  width: 100%;
  height: 970px;
  background: url("../../../assets/img/home/about_bg.jpg") no-repeat center bottom;
  background-size: cover;
  position: relative;
}

.white-bg {
  position: absolute;
  top: 0;
  right: 0;
  width: 60%;
  bottom: 0;
  background-color: rgba(240, 240, 240, .7);
}

.about-wrap {
  position: absolute;
  display: flex;

  .txt-wrap {
    width: 40%;
    text-align: left;

    h2 {
      font-size: 60px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #1890FF;
      padding-top: 25%;
      padding-bottom: 2%;
      padding-left: 30%;
    }

    p {
      padding-left: 30%;
      font-size: 52px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #FFFFFF;
    }
  }

  .info-wrap {
    width: 60%;

    .txt {
      text-align: left;
      width: 66%;
      margin-top: 16%;
      margin-left: 22%;
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #1E2432;
      line-height: 36px;
      text-indent: 2em;
    }

    .icon-box {
      width: 66%;
      margin-top: 4%;
      margin-left: 24%;
      display: flex;

      .icon-item {
        flex: 1;
        cursor: pointer;

        .item-img {
          width: 124px;
          height: 124px;
          background: #69C0FF;
          border-radius: 50%;
          line-height: 124px;
          margin: 0 auto;

          i {
            color: #ffffff;
            font-size: 60px;
          }
        }

        p {
          line-height: 60px;
          font-size: 24px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
        }
      }

      .icon-item:hover {
        .item-img {
          background: #1890FF;
        }
      }
    }

  }
}

@media (max-width: 1300px) {
  .about-wrap {
    .txt-wrap {
      width: 36%;

      h2 {
        font-size: 56px;
        padding-top: 28%;
      }

      p {
        font-size: 48px;
      }
    }

    .info-wrap {
      width: 64%;

      .txt {
        width: 74%;
        margin-left: 18%;
        margin-top: 14%;
      }

      .icon-box {
        width: 74%;
        margin-left: 18%;

        .icon-item {
          flex: 1;

          .item-img {
            width: 100px;
            height: 100px;
            line-height: 100px;

            i {
              font-size: 50px;
            }
          }

          p {
            font-size: 20px;
          }
        }
      }
    }
  }
}

@media (max-width: 1050px) {
  .about-wrap {
    .txt-wrap {
      width: 30%;

      h2 {
        font-size: 48px;
      }

      p {
        font-size: 38px;
      }
    }

    .info-wrap {
      width: 70%;

      .txt {
        width: 80%;
        margin-left: 18%;
        margin-top: 10%;

        p {
          font-size: 17px;
        }
      }

      .icon-box {
        width: 80%;
      }
    }
  }
}

@media (max-width: 768px ) {
  .about-wrap {
    position: relative;
    display: block;

    .txt-wrap {
      width: 100%;
      text-align: center;
      margin-bottom: 1%;

      h2 {
        font-size: 40px;
        padding-left: 0;
        padding-top: 5%;
      }

      p {
        font-size: 28px;
        padding-left: 0;
        color: #86b5dc;
      }
    }

    .info-wrap {
      width: 100%;
      overflow: auto;
      height: 800px;
      background-color: rgba(240, 240, 240, .7);
      padding: 0.2% 0;

      .txt {
        text-align: left;
        width: 88%;
        margin: 5% auto;
        font-size: 16px;
        line-height: 30px;
        text-indent: 2em;
      }

      .icon-box {
        width: 80%;
        margin: 4% auto;
        display: flex;

        .icon-item {
          flex: 1;
          cursor: pointer;

          .item-img {
            width: 80px;
            height: 80px;
            line-height: 80px;

            i {
              font-size: 30px;
            }
          }

          p {
            line-height: 50px;
            font-size: 18px;
          }
        }

      }

    }
  }

  .white-bg {
    display: none;
  }
}

@media (max-height: 820px ) {
  .about-wrap {
    position: relative;
    display: block;

    .txt-wrap {
      width: 100%;
      text-align: center;
      margin-bottom: 1%;

      h2 {
        font-size: 40px;
        padding-left: 0;
        padding-top: 5%;
      }

      p {
        color: #86b5dc;
        font-size: 28px;
        padding-left: 0;
      }
    }

    .info-wrap {
      width: 100%;
      overflow: auto;
      height: 800px;
      background-color: rgba(240, 240, 240, .7);
      padding: 0.2% 0;

      .txt {
        text-align: left;
        width: 88%;
        margin: 5% auto;
        font-size: 16px;
        line-height: 30px;
        text-indent: 2em;
      }

      .icon-box {
        width: 80%;
        margin: 4% auto;
        display: flex;

        .icon-item {
          flex: 1;
          cursor: pointer;

          .item-img {
            width: 80px;
            height: 80px;
            line-height: 80px;

            i {
              font-size: 30px;
            }
          }

          p {
            line-height: 50px;
            font-size: 18px;
          }
        }

      }

    }
  }

  .white-bg {
    display: none;
  }
}

</style>
