<template>
  <div class="banner-three">
    <div class="txt-box">
      <p class="title animate__animated" :class="[isOn==3?'animate__bounceIn':'']">智能制造2025</p>
      <p class="sub-txt animate__animated animate__delay-1s" :class="[isOn==3?'animate__fadeInUp':'']">
        云昇新控通过连接物理世界与数字世界改造产业</p>
      <p class="sub-txt animate__animated animate__delay-2s" :class="[isOn==3?'animate__fadeInUp':'']">
        云昇新控推动传统企业向智能企业的演进</p>
    </div>
  </div>
</template>

<script>

export default {
  name: 'banner-three',
  components: {},
  props: {
    isOn: {
      type: Number,
      default: 0
    }
  }
}
</script>

<style scoped lang="less">
.banner-three {
  width: 100%;
  height: 100%;
  background: url("../../../../assets/img/home/banner3.png") no-repeat center;
  background-size: cover;

  .txt-box {
    text-align: center;
    position: relative;
    top: 20%;
    font-family: Microsoft YaHei;
    font-weight: 400;

    .title {
      color: #ffffff;
      font-size: 72px;
      line-height: 2.6;
      font-weight: bold;
    }

    .sub-txt {
      font-size: 36px;
      color: #7AE2FF;
      line-height: 1.8;
    }
  }
}

@media (max-width: 1200px) {
  .banner-three {
    .txt-box {
      top: 20%;

      .title {
        font-size: 60px;
        line-height: 2.6;
      }

      .sub-txt {
        font-size: 28px;
        color: #7AE2FF;
        line-height: 1.8;
      }
    }
  }
}

@media (max-width: 992px) {
  .banner-three {
    .txt-box {
      top: 30%;

      .title {
        font-size: 48px;
        line-height: 2.6;
      }

      .sub-txt {
        font-size: 18px;
        color: #7AE2FF;
        line-height: 1.8;
      }
    }
  }
}

</style>
