<template>
  <div class="banner-five"><h2 v-show="false">云昇新控官网-重庆云昇新控智能科技有限公司</h2></div>
</template>

<script>

export default {
  name: 'banner-five',
  components: {},
  props: {
    isOn: {
      type: Number
    }
  }
}
</script>

<style scoped lang="less">
.banner-five {
  width: 100%;
  height: 100%;
  background: url("../../../../assets/img/home/banner5.jpg") no-repeat center;
  background-size: cover;
}

@media (max-width: 768px) {
  // 变成手机模式
  .banner-five {
    background: url("../../../../assets/img/home/phone_banner5.jpg") no-repeat center;
    background-size: cover;
  }
}
</style>
