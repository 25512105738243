<template>
  <div>
    <div class="nav-wrap" :class="[theme==='white'?'white-nav':'']">
      <router-link to="/" class="logo"></router-link>
      <ul class="nav-box">
        <li class="nav-item" :class="[isActive==='home'?'on':'']">
          <router-link to="/">首页</router-link>
        </li>
        <li class="nav-item" :class="[isActive==='product'?'on':'']">
          <template><a>产品中心</a></template>
          <div class="sub-nav">
            <div class="ly-center">
              <div class="images nav-cp"></div>
              <div class="sub-box">
                <ul>
                  <li>
                    <router-link to="/product/water">智慧水务</router-link>
                  </li>
                  <li>
                    <router-link to="/product/integrate">自动化系统集成</router-link>
                  </li>
                  <li>
                    <router-link to="/product/ggd">高低压配电柜</router-link>
                  </li>
                </ul>
                <ul>
                  <li>
                    <router-link to="/product/instrument">仪器仪表</router-link>
                  </li>
                  <li>
                    <router-link to="/product/factory">智慧工厂</router-link>
                  </li>
                  <li>
                    <router-link to="/product/lighting">智慧照明</router-link>
                  </li>
                </ul>
                <ul>
                  <li>
                    <router-link to="/product/pipe">智慧管廊</router-link>
                  </li>
                  <li>
                    <router-link to="/product/fire">智慧消防</router-link>
                  </li>
                  <li>
                    <router-link to="/product/farm">智慧农业</router-link>
                  </li>
                </ul>
                <ul>
                  <li>
                    <router-link to="/product/irrigation">智慧灌区</router-link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </li>
        <li class="nav-item" :class="[isActive==='programme'?'on':'']">
          <template><a>方案中心</a></template>
          <div class="sub-nav">
            <div class="ly-center">
              <div class="images nav-fa"></div>
              <div class="sub-box">
                <ul>
                  <li>
                    <router-link to="/programme/water">智慧水务</router-link>
                  </li>
                  <li>
                    <router-link to="/programme/integrate">自动化系统集成</router-link>
                  </li>
                  <li>
                    <router-link to="/programme/factory">智慧工厂</router-link>
                  </li>
                </ul>
                <ul>
                  <li>
                    <router-link to="/programme/lighting">智慧照明</router-link>
                  </li>
                  <li>
                    <router-link to="/programme/pipe">智慧管廊</router-link>
                  </li>
                  <li>
                    <router-link to="/programme/fire">智慧消防</router-link>
                  </li>
                </ul>
                <ul>
                  <li>
                    <router-link to="/programme/farm">智慧农业</router-link>
                  </li>
                  <li>
                    <router-link to="/programme/irrigation">智慧灌区</router-link>
                  </li>
                </ul>
              </div>
            </div>

          </div>
        </li>
        <li class="nav-item sm-item" :class="[isActive==='service'?'on':'']">
          <template><a>服务支持</a></template>
          <div class="sub-nav small-nav">
            <ul>
              <li>
                <router-link to="/service/message">留言</router-link>
              </li>
              <li>
                <router-link to="/service/after-sales">售后服务</router-link>
              </li>
            </ul>
          </div>
        </li>
        <li class="nav-item" :class="[isActive==='case'?'on':'']">
          <router-link to="/case">案例中心</router-link>
        </li>
        <li class="nav-item" :class="[isActive==='about'?'on':'']">
          <router-link to="/about">关于我们</router-link>
        </li>
      </ul>
      <div class="phone"><i class="iconfont icon-phone"></i><a href="tel: 023-6780 9905">023-6780 9905</a>
      </div>
    </div>
    <div class="phone-nav">
      <div class="nav-title">
        <router-link to="/" class="logo"></router-link>
        <div class="phone"><i class="iconfont icon-phone"></i><a href="tel: 023-6780 9905">023-6780 9905</a></div>
        <div class="menu" @click="showMenu=!showMenu"><i class="iconfont icon-caidan"></i></div>
      </div>
      <el-menu v-show="showMenu"
               default-active="product"
               class="phone-menu">
        <el-menu-item index="home">
          <span slot="title">
          <router-link to="/">首页</router-link>
          </span>
        </el-menu-item>
        <el-submenu index="product">
          <template slot="title">
            <span>产品中心</span>
          </template>
          <el-menu-item index="product/water">
            <router-link to="/product/water">智慧水务</router-link>
          </el-menu-item>
          <el-menu-item index="product/integrate">
            <router-link to="/product/integrate">自动化系统集成</router-link>
          </el-menu-item>
          <el-menu-item index="product/lighting">
            <router-link to="/product/lighting">智慧照明</router-link>
          </el-menu-item>
          <el-menu-item index="product/instrument">
            <router-link to="/product/instrument">仪器仪表</router-link>
          </el-menu-item>
          <el-menu-item index="product/factory">
            <router-link to="/product/factory">智慧工厂</router-link>
          </el-menu-item>
          <el-menu-item index="product/ggd">
            <router-link to="/product/ggd">高低压配电柜</router-link>
          </el-menu-item>
          <el-menu-item index="product/irrigation">
            <router-link to="/product/irrigation">智慧灌区</router-link>
          </el-menu-item>
          <el-menu-item index="product/pipe">
            <router-link to="/product/pipe">智慧管廊</router-link>
          </el-menu-item>
          <el-menu-item index="product/fire">
            <router-link to="/product/fire">智慧消防</router-link>
          </el-menu-item>
          <el-menu-item index="product/farm">
            <router-link to="/product/farm">智慧农业</router-link>
          </el-menu-item>
        </el-submenu>
        <el-submenu index="programme">
          <template slot="title">
            <span>方案中心</span>
          </template>
          <el-menu-item index="programme/water">
            <router-link to="/programme/water">智慧水务</router-link>
          </el-menu-item>
          <el-menu-item index="programme/integrate">
            <router-link to="/programme/integrate">自动化系统集成</router-link>
          </el-menu-item>
          <el-menu-item index="programme/factory">
            <router-link to="/programme/factory">智慧工厂</router-link>
          </el-menu-item>
          <el-menu-item index="programme/irrigation">
            <router-link to="/programme/irrigation">智慧灌区</router-link>
          </el-menu-item>
          <el-menu-item index="programme/pipe">
            <router-link to="/programme/pipe">智慧管廊</router-link>
          </el-menu-item>
          <el-menu-item index="programme/fire">
            <router-link to="/programme/fire">智慧消防</router-link>
          </el-menu-item>
          <el-menu-item index="programme/farm">
            <router-link to="/programme/farm">智慧农业</router-link>
          </el-menu-item>
          <el-menu-item index="programme/lighting">
            <router-link to="/programme/lighting">智慧照明</router-link>
          </el-menu-item>
        </el-submenu>
        <el-submenu index="service">
          <template slot="title">
            <span>服务支持</span>
          </template>
          <el-menu-item index="/service/message">
            <router-link to="/service/message">留言</router-link>
          </el-menu-item>
          <el-menu-item index="/service/after-sales">
            <router-link to="/service/after-sales">售后服务</router-link>
          </el-menu-item>
        </el-submenu>
        <el-menu-item index="case">
          <router-link to="/case">案例中心</router-link>
        </el-menu-item>
        <el-menu-item index="about">
          <router-link to="/about">关于我们</router-link>
        </el-menu-item>
      </el-menu>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Header',
  props: {
    isActive: {
      type: String,
      default: 'home'
    },
    theme: {
      type: String,
      default: 'white'
    }
  },
  data () {
    return {
      showMenu: false
    }
  }
}
</script>

<style scoped lang="less">
.nav-wrap {
  width: 100%;
  height: 90px;
  background-color: rgba(0, 0, 0, .65);
  display: flex;
  z-index: 999;
  position: fixed;

  .logo {
    width: 188px;
    height: 66px;
    margin-top: 10px;
    background: url("../assets/img/logo_188.png") no-repeat center;
    background-size: contain;
    display: inline-block;
    margin-left: 3%;
    margin-right: 20%;
  }

  .nav-box {
    flex: 1;
    height: 90px;
    display: flex;

    .nav-item {
      flex: 1;
      cursor: pointer;

      a {
        line-height: 90px;
        width: 100%;
        height: 100%;
        display: block;
        font-size: 18px;
        font-weight: 400;
        color: #FFFFFF;
        text-decoration: none;
      }

      //   子导航
      .sub-nav {
        display: none;
        position: absolute;
        z-index: 999;
        left: 0;
        right: 0;
        width: 100%;
        background-color: white;
        color: #333333;
        text-align: center;
        padding-top: 25px;
        padding-bottom: 20px;
        border-top: 1px solid #E1E1E1;

        .images {
          width: 270px;
          height: 180px;
          display: inline-block;
          margin-right: 6%;
        }

        .nav-cp {
          background: #15154f url("../assets/img/nav-cp.jpg") no-repeat center;
          background-size: contain;
        }

        .nav-fa {
          background: #15154f url("../assets/img/nav-fa.png") no-repeat center;
          background-size: contain;
        }

        .sub-box {
          width: auto;
          height: 180px;
          display: inline-block;

          ul {
            margin-top: 25px;
            width: 190px;
            float: left;
            height: 120px;
            overflow: hidden;
            border-left: 1px solid #E1E1E1;

            li {
              text-align: left;

              a {
                text-indent: 12px;
                line-height: 40px;
                font-weight: 400;
                font-size: 18px;
                color: #8C8C8C;
              }
            }

            li:hover {
              a {
                width: 100%;
                height: 100%;
                display: block;
                background-color: #ffffff;
                color: #69C0FF;
              }
            }
          }
        }

      }

    }

    .nav-item:hover {
      background-color: #1890FF;

      a {
        color: #ffffff;
      }

      .sub-nav {
        display: block;
      }
    }

    .on a {
      color: #1890FF;
    }

    .sm-item {
      position: relative;

      .small-nav {
        width: 183px;
        position: absolute;
        left: 0;
        padding-top: 0;
        padding-bottom: 0;
        background-color: rgba(255, 255, 255, .9);

        li {
          text-align: left;

          a {
            text-indent: 12px;
            line-height: 60px;
            font-weight: 400;
            color: #595959;
            font-size: 18px;
            border-top: 1px solid #D9D9D9;
          }
        }

        a:hover {
          color: #69C0FF;
        }
      }
    }

  }

  .phone {
    width: 188px;
    height: 66px;
    line-height: 66px;
    margin-top: 10px;
    text-align: right;
    display: inline-block;
    margin-left: 4%;
    margin-right: 4%;
    color: #ffffff;

    i {
      font-size: 20px;
      padding-right: 5px;
    }

    a {
      color: #ffffff;
    }
  }

}

.white-nav {
  background-color: #ffffff;

  .logo {
    background: url("../assets/img/logo_188_w.png") no-repeat center;
    background-size: contain;
  }

  .nav-box {
    .nav-item {
      color: #141414;

      a {
        color: #141414;
      }
    }

    .on a {
      color: #1890FF;
    }

    .nav-item:hover {
      background-color: #1890FF;

      a {
        color: #ffffff;
      }
    }
  }

  .phone {
    color: #141414;

    a {
      color: #141414;
    }
  }
}

.phone-nav {
  width: 100%;
  height: 50px;
  z-index: 999;
  position: fixed;
  background-color: #ffffff;
  text-align: left;
  display: none;

  .nav-title {
    width: 100%;
    height: 50px;
    overflow: hidden;

    .logo {
      width: 30%;
      height: 40px;
      //margin-top: 5px;
      background: url("../assets/img/logo_188_w.png") no-repeat left bottom;
      background-size: contain;
      display: inline-block;
      float: left;
      margin-left: 5%;
    }

    .phone {
      width: 45%;
      text-align: right;
      line-height: 50px;
      padding-right: 5%;
      font-size: 16px;
      float: left;

      i {
        font-size: 20px;
      }

      a {
        color: #333333;
      }
    }

    .menu {
      width: 10%;
      height: 50px;
      line-height: 50px;
      padding-right: 5%;
      float: right;

      i {
        font-size: 36px;
      }
    }
  }

  .phone-menu {
    width: 100%;
    height: auto;
    position: relative;
    z-index: 999;

    a {
      display: block;
      color: #333333;
    }
  }

}

@media (max-width: 1300px) {
  .nav-wrap {
    //background-color: red;

    .logo {
      margin-right: 6%;
    }

    .phone {
      margin-left: 3%;
    }
  }
}

@media (max-width: 1000px) {
  .nav-wrap {
    //background-color: pink;
    height: 80px;

    .logo {
      width: 120px;
      height: 60px;
      margin-right: 4%;
    }

    .nav-box {
      height: 80px;

      .nav-item {
        height: 80px;
        line-height: 80px;
        font-size: 16px;
      }
    }

    .phone {
      width: 140px;
      height: 60px;
    }
  }
}

@media (max-width: 850px) {
  // 变成手机模式
  .nav-wrap {
    background-color: orange;
    overflow: hidden;
    display: none;
  }

  .phone-nav {
    display: block;
  }
}

</style>
