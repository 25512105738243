<template>
  <div>
    <div class="back-top-wrap">
      <div class="contact" @click="contactVisible=true">
        <div class="icon">
          <i class="iconfont icon-chat"></i>
        </div>
        <div class="txt">
          <p>联</p>
          <p>系</p>
          <p>我</p>
          <p>们</p>
        </div>
      </div>
      <div class="top" @click="toTop" v-show="showGo">
        <i class="iconfont icon-top"></i>
      </div>
    </div>
    <el-dialog :visible.sync="contactVisible" title="联系我们" width="240px">
      <div class="contact-info-wrap">
        <a
          class="info-item"
          href="tencent://message/?Menu=yes&uin=384799535& Service=300&sigT=45a1e5847943b64c6ff3990f8a9e644d2b31356cb0b4ac6b24663a3c8dd0f8aa12a595b1714f9d45">
          <div class="icon">
            <i class="iconfont icon-help"></i>
          </div>
          <div class="txt">
            <p class="title">在线咨询</p>
            <p>咨询人工服务</p>
          </div>
        </a>
        <a class="info-item" href="tel: 023-6780 9905">
          <div class="icon">
            <i class="iconfont icon-tel"></i>
          </div>
          <div class="txt">
            <p class="title">咨询电话</p>
            <p>023-6780 9905</p>
          </div>
        </a>
        <router-link class="info-item" to="/service/message">
          <div class="icon">
            <i class="iconfont icon-liuyan"></i>
          </div>
          <div class="txt">
            <p class="title">留言反馈</p>
            <p>希望聆听您的建议</p>
          </div>
        </router-link>
        <a class="info-item" @click="popVisible=true">
          <div class="icon">
            <i class="iconfont icon-gzh"></i>
          </div>
          <div class="txt">
            <p class="title">微信公众号</p>
            <p>扫码关注</p>
          </div>
        </a>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="popVisible" title="云昇新控公众号" width="50%">
      <div class="auto-img">
        <img src="../assets/img/ewm_big.jpg" alt="云昇新控公众号">
      </div>
    </el-dialog>
  </div>
</template>
<script>

export default {
  name: 'BacKTop',
  components: {},
  data () {
    return {
      contactVisible: false,
      popVisible: false, // 二维码弹框
      showGo: false
    }
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll, true)
  },
  methods: {
    handleOpen () {
      this.contactVisible = true
    },
    handleScroll () {
      const scrolltop = document.documentElement.scrollTop || document.body.scrollTop
      scrolltop > 30 ? (this.showGo = true) : (this.showGo = false)
    },
    toTop () {
      let top = document.documentElement.scrollTop || document.body.scrollTop
      // 实现滚动效果
      const timeTop = setInterval(() => {
        document.body.scrollTop = document.documentElement.scrollTop = top -= 50
        if (top <= 0) {
          clearInterval(timeTop)
        }
      }, 10)
    }
  }
}
</script>
<style lang="less" scoped>
.back-top-wrap {
  width: 60px;
  height: auto;
  position: fixed;
  right: 0;
  bottom: 10%;
  z-index: 999;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #1890FF;

  i {
    font-size: 30px;
  }

  .contact {
    width: 60px;
    background: #FAFAFA;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.19);
    border-radius: 10px 0px 0px 10px;
    text-align: center;
    font-size: 18px;
    padding: 20px 0;
    cursor: pointer;
  }

  .top {
    margin-top: 20px;
    width: 60px;
    height: 60px;
    background: #FAFAFA;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.19);
    border-radius: 10px 0px 0px 10px;
    line-height: 2;
    cursor: pointer;
  }
}

.contact-info-wrap {
  width: 100%;
  height: auto;

  .info-item {
    padding: 0 5% 5%;
    display: flex;

    .icon {
      i {
        color: #1890FF;
        font-size: 45px;
      }

      padding-left: 10px;
      padding-right: 10px;
    }

    .txt {
      font-family: Microsoft YaHei;
      font-weight: 400;
      text-align: left;

      .title {
        font-size: 18px;
        color: #333333;
      }

      p {
        font-size: 14px;
        color: #999999;

        a {
          color: #999999;
        }
      }
    }
  }

  .info-item:hover {
    text-decoration: none;

    .txt {
      .title {
        color: #1890FF;
      }

      p {
        color: #69C0FF;
      }
    }
  }
}

.auto-img {
  img {
    max-width: 100%;
    height: auto;
  }
}

@media (max-width: 749px) {
  .back-top-wrap {
    width: 40px;

    i {
      font-size: 24px;
    }

    .contact {
      width: 40px;
      font-size: 14px;
      padding: 10px 0;
    }

    .top {
      margin-top: 10px;
      width: 40px;
      height: 40px;
      line-height: 40px;

      i {
        font-size: 18px;
      }
    }
  }
}

</style>
