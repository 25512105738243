<template>
  <div class="section-five box-height-wrap">
    <div class="partner-wrap">
      <h2>云昇新控-合作伙伴</h2>
      <div class="center">
        <div class="pt-box animate__animated" :class="[isCurrent==4?'animate__slideInUp':'']">
          <el-row :gutter="10">
            <el-col :xs="12" :sm="12" :md="6" :lg="6" :xl="6">
              <div class="auto-img">
                <img src="../../../assets/img/home/lg_hw.png" alt="华为">
              </div>
            </el-col>
            <el-col :xs="12" :sm="12" :md="6" :lg="6" :xl="6">
              <div class="auto-img">
                <img src="../../../assets/img/home/lg_xmz.png" alt="西门子">
              </div>
            </el-col>
            <el-col :xs="12" :sm="12" :md="6" :lg="6" :xl="6">
              <div class="auto-img">
                <img src="../../../assets/img/home/lg_abb.png" alt="ABB">
              </div>
            </el-col>
            <el-col :xs="12" :sm="12" :md="6" :lg="6" :xl="6">
              <div class="auto-img">
                <img src="../../../assets/img/home/lg_dell.png" alt="戴尔">
              </div>
            </el-col>
            <el-col :xs="12" :sm="12" :md="6" :lg="6" :xl="6">
              <div class="auto-img">
                <img src="../../../assets/img/home/lg_hkws.png" alt="海康威视">
              </div>
            </el-col>
            <el-col :xs="12" :sm="12" :md="6" :lg="6" :xl="6">
              <div class="auto-img">
                <img src="../../../assets/img/home/lg_cqsw.png" alt="重庆水务">
              </div>
            </el-col>
            <el-col :xs="12" :sm="12" :md="6" :lg="6" :xl="6">
              <div class="auto-img">
                <img src="../../../assets/img/home/lg_cnsw.png" alt="中国水务">
              </div>
            </el-col>
            <el-col :xs="12" :sm="12" :md="6" :lg="6" :xl="6">
              <div class="auto-img">
                <img src="../../../assets/img/home/lg_zsy.png" alt="中石油">
              </div>
            </el-col>
            <el-col :xs="12" :sm="12" :md="6" :lg="6" :xl="6">
              <div class="auto-img">
                <img src="../../../assets/img/home/lg_cngc.png" alt="中国兵器">
              </div>
            </el-col>
            <el-col :xs="12" :sm="12" :md="6" :lg="6" :xl="6">
              <div class="auto-img">
                <img src="../../../assets/img/home/lg_ca.png" alt="长安">
              </div>
            </el-col>
            <el-col :xs="12" :sm="12" :md="6" :lg="6" :xl="6">
              <div class="auto-img">
                <img src="../../../assets/img/home/lg_snd.png" alt="施耐德">
              </div>
            </el-col>
            <el-col :xs="12" :sm="12" :md="6" :lg="6" :xl="6">
              <div class="auto-img">
                <img src="../../../assets/img/home/lg_lb.png" alt="立邦">
              </div>
            </el-col>
          </el-row>

        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'section_partner',
  components: {},
  props: {
    isCurrent: {
      type: Number,
      default: 4
    }
  },
  data () {
    return {}
  },
  computed: {}
}
</script>

<style lang="less" scoped>
@import '../../../assets/css/ing.less';

.section-five {
  width: 100%;
  background: url("../../../assets/img/home/partner_bg.jpg") no-repeat center bottom;
  background-size: cover;
  position: relative;
}

.partner-wrap {
  width: 100%;
  padding-top: 1%;

  h2 {
    font-size: 48px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #FFFFFF;
    padding: 5% 0 3%;
  }
}

.center {
  width: 86%;
  height: auto;
  margin: 0 auto;

  .pt-box {
    width: 100%;
    height: auto;

    .auto-img {
      margin-top: 5%;

      img {
        max-width: 100%;
        height: auto;
      }
    }

    .auto-img:hover {
      animation: proRotate 1s ease-in-out;
    }
  }
}

@keyframes proRotate {
  0% {
    transform: perspective(400px) rotateY(180deg);
  }
  100% {
    transform: perspective(400px) rotateY(0deg);
  }
}

</style>
