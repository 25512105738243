<template>
  <div class="banner-four">
    <div class="txt-box">
      <h1 class="title animate__animated" :class="[isOn==2?'animate__fadeInRight':'']">云昇新控 <span v-show="false">官网</span></h1>
      <p class="sub-txt animate__animated animate__delay-1s" :class="[isOn==2?'animate__fadeInRight':'']">助力2030
        <em>碳达峰</em></p>
      <p class="sub-txt animate__animated animate__delay-2s" :class="[isOn==2?'animate__fadeInRight':'']"
         style="text-indent: 20%;">助力2060 <em>碳中和</em></p>
    </div>
  </div>
</template>

<script>

export default {
  name: 'banner-four',
  components: {},
  props: {
    isOn: {
      type: Number,
      default: 0
    }
  }
}
</script>

<style scoped lang="less">
.banner-four {
  width: 100%;
  height: 100%;
  background: url("../../../../assets/img/home/banner4.jpg") no-repeat center;
  background-size: cover;

  .txt-box {
    text-align: center;
    position: relative;
    top: 26%;
    font-family: Microsoft YaHei;
    font-weight: 400;

    .title {
      color: #028E0B;
      font-size: 110px;
      line-height: 1.6;
      text-indent: 10%;
      font-weight: bold;
    }

    .sub-txt {
      font-size: 74px;
      color: #047201;
      text-indent: 10%;
      line-height: 1.6;

      em {
        font-weight: bold;
      }
    }
  }
}

@media (max-width: 1200px) {
  .banner-four {
    .txt-box {
      top: 26%;

      .title {
        font-size: 80px;
        line-height: 1.6;
        text-indent: 10%;
      }

      .sub-txt {
        font-size: 50px;
        text-indent: 10%;
        line-height: 1.6;
      }
    }
  }
}

@media (max-width: 992px) {
  .banner-four {
    .txt-box {
      top: 30%;

      .title {
        font-size: 60px;
        line-height: 1.6;
        text-indent: 10%;
      }

      .sub-txt {
        font-size: 30px;
        text-indent: 10%;
        line-height: 1.6;
      }
    }
  }
}

@media (max-width: 768px) {
  // 变成手机模式
  .banner-four {
    background: url("../../../../assets/img/home/phone_banner_4.jpg") no-repeat center;
    background-size: cover;

    .txt-box {
      top: 20%;

      .title {
        font-size: 60px;
        line-height: 1.6;
        text-indent: 10%;
        color: #C6DD00;
      }

      .sub-txt {
        color: #ffffff;
        font-size: 30px;
        text-indent: 10%;
        line-height: 1.6;
      }
    }
  }
}
</style>
