import Vue from 'vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import animated from 'animate.css'
import 'fullpage.js/vendors/scrolloverflow' // Optional. When using scrollOverflow:true
// import './fullpage.scrollHorizontally.min' // Optional. When using fullpage extensions
import VueFullPage from 'vue-fullpage.js'
import '../public/css/fullpage.min.css'
import '../public/css/iconfont/iconfont.css'
import App from './App.vue'
import router from './router'
import store from './store'
import VueWechatTitle from 'vue-wechat-title'
import MetaInfo from 'vue-meta-info'

Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.use(animated)
Vue.use(VueFullPage)
Vue.use(VueWechatTitle)
Vue.use(MetaInfo)

router.afterEach((to, from, next) => {
  window.scrollTo(0, 0)

  setTimeout(() => {
    var _hmt = _hmt || [];
    (function () {
      // 每次执行前，先移除上次插入的代码
      document.getElementById('baidu_tj') && document.getElementById('baidu_tj').remove()
      var hm = document.createElement('script')
      hm.src = 'https://hm.baidu.com/hm.js?c93421ed868e4c4d60fe6ab81e15cb9c'
      hm.id = 'baidu_tj'
      var s = document.getElementsByTagName('script')[0]
      s.parentNode.insertBefore(hm, s)
    })()
  }, 0)
})
// 引入百度统计

new Vue({
  router,
  store,
  render: h => h(App),
  mounted () {
    document.dispatchEvent(new Event('render-event'))
  }
}).$mount('#app')
