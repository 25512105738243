<template>
  <div>
    <div class="footer-wrap">
      <div class="center-wrap">
        <div class="ysxk-bg"></div>
        <el-row>
          <el-col :xs="24" :sm="24" :md="24" :lg="9" :xl="9">
            <div class="left">
              <el-row>
                <el-col :xs="18" :sm="18" :md="18" :lg="24" :xl="24">
                  <div class="logo"></div>
                  <div class="address-box">
                    <p><i class="iconfont icon-dizhi"></i><a target="_blank"
                                                             href="http://api.map.baidu.com/marker?location=29.6473,106.555455&title=我的位置&content=重庆云昇新控智能科技有限公司&output=html&src=webapp.baidu.openAPIdemo">地址：重庆市渝北区金开大道1003号22层2号</a>
                    </p>
                    <p><i class="iconfont icon-phone"></i>电话：<a href="tel: 023-6780 9905">023-6780 9905</a></p>
                    <p><i class="iconfont icon-mail"></i>邮箱：<a href="mailto:marketing@yunsxk.com" target="_blank">marketing@yunsxk.com</a>
                    </p>
                  </div>
                </el-col>
                <el-col :xs="6" :sm="6" :md="6" :lg="24" :xl="24">
                  <div class="ewm-box">
                    <div class="ewm-img"></div>
                    <p>扫码关注微信公众号</p>
                  </div>
                </el-col>
              </el-row>
            </div>
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="15" :xl="15">
            <div class="right">
              <ul class="nav-item">
                <li class="title">产品中心</li>
                <li>
                  <router-link to="/product/water">智慧水务</router-link>
                </li>
                <li>
                  <router-link to="/product/integrate">自动化系统集成</router-link>
                </li>
                <li>
                  <router-link to="/product/ggd">高低压配电柜</router-link>
                </li>
                <li>
                  <router-link to="/product/instrument">仪器仪表</router-link>
                </li>
                <li>
                  <router-link to="/product/factory">智慧工厂</router-link>
                </li>
                <li>
                  <router-link to="/product/lighting">智慧照明</router-link>
                </li>
                <li>
                  <router-link to="/product/pipe">智慧管廊</router-link>
                </li>
                <li>
                  <router-link to="/product/fire">智慧消防</router-link>
                </li>
                <li>
                  <router-link to="/product/farm">智慧农业</router-link>
                </li>
                <li>
                  <router-link to="/product/irrigation">智慧灌区</router-link>
                </li>
              </ul>
              <ul class="nav-item">
                <li class="title">方案中心</li>
                <li>
                  <router-link to="/programme/water">智慧水务</router-link>
                </li>
                <li>
                  <router-link to="/programme/integrate">系统集成</router-link>
                </li>
                <li>
                  <router-link to="/programme/factory">智慧工厂</router-link>
                </li>
                <li>
                  <router-link to="/programme/lighting">智慧照明</router-link>
                </li>
                <li>
                  <router-link to="/programme/pipe">智慧管廊</router-link>
                </li>
                <li>
                  <router-link to="/programme/fire">智慧消防</router-link>
                </li>
                <li>
                  <router-link to="/programme/farm">智慧农业</router-link>
                </li>
                <li>
                  <router-link to="/programme/irrigation">智慧灌区</router-link>
                </li>
              </ul>
              <ul class="nav-item">
                <li class="title">服务支持</li>
                <li>
                  <router-link to="/service/message">留言</router-link>
                </li>
                <li>
                  <router-link to="/service/after-sales">售后服务</router-link>
                </li>
              </ul>
              <ul class="nav-item">
                <li class="title">案例中心</li>
                <li>
                  <router-link to="/case">案例中心</router-link>
                </li>
              </ul>
              <ul class="nav-item">
                <li class="title">关于我们</li>
                <li>
                  <router-link to="/about">企业简介</router-link>
                </li>
                <li>
                  <router-link to="/about">企业文化</router-link>
                </li>
                <li>
                  <router-link to="/about">加入我们</router-link>
                </li>
                <li>
                  <router-link to="/about">联系我们</router-link>
                </li>
              </ul>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="line"></div>
      <div class="center-wrap down-wrap">
        <el-row style="z-index: 99">
          <el-col :xs="24" :sm="5" :md="5" :lg="5" :xl="5" class="one">
            <span><router-link to="/article/privacy-security">隐私与安全</router-link></span>
            <span>|</span>
            <span><router-link to="/article/legal-statement">法律声明</router-link></span>
          </el-col>
          <el-col :xs="24" :sm="7" :md="7" :lg="8" :xl="8" class="tow">
            <span>Copyright © 2021 云昇新控-版权所有</span>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="11" :xl="11" class="three">
            <span>友情链接：</span>
            <span><a href="http://yunsxk.com:7081/#/user/login?redirect=%2F" target="_blank">智慧水务平台</a></span>
            <span><a href="https://jjxxw.cq.gov.cn/" target="_blank">重庆市经信委</a></span>
            <span><a href="http://slj.cq.gov.cn/" target="_blank">重庆市水利局</a></span>
          </el-col>
          <el-col class="four">
            <span>All rights reserved. <a href="https://beian.miit.gov.cn/" target="_blank">渝ICP备20008317号</a>
               <i class="police"></i></span>
          </el-col>
        </el-row>
      </div>
      <div class="logo-bg"></div>
    </div>

    <div class="phone-footer-wrap">
      <div class="max-center">
        <p>All rights reserved.<a href="https://beian.miit.gov.cn/" target="_blank">渝ICP备20008317号</a></p>
        <p>Copyright © 2021 云昇新控-版权所有</p>
      </div>
      <div class="line"></div>
      <div class="max-center" style="padding-top: 10px;">
        <p>
          <router-link to="/about">联系我们</router-link>
          <span>|</span>
          <router-link to="/article/privacy-security">隐私与安全</router-link>
          <span>|</span>
          <router-link to="/article/legal-statement">法律声明</router-link>
        </p>
      </div>
    </div>

  </div>
</template>

<script>

export default {
  name: 'Footer',
  props: {}
}
</script>

<style scoped lang="less">
@import '../assets/css/ing.less';

.footer-wrap {
  background-color: #333333;
  color: #ffffff;
  position: relative;

  .logo-bg {
    width: 20%;
    height: 35%;
    position: absolute;
    background: url("../assets/img/logo_bg.png") no-repeat center;
    background-size: contain;
    top: 55%;
    left: 1%;
  }
}

.center-wrap {
  width: 68%;
  height: auto;
  margin: 0 auto;
  padding-top: 3%;
  text-align: left;

  .left {

    .logo {
      width: 216px;
      height: 56px;
      background: url("../assets/img/logo_188.png") no-repeat center;
      background-size: contain;
    }

    .address-box {
      padding-top: 6%;

      i {
        width: 20px;
        display: inline-block;
        font-size: 15px;
        text-align: center;
        padding-right: 10px;
      }

      p, a {
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 39px;
      }
    }

    .ewm-box {
      width: 150px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      padding-top: 10%;
      line-height: 38px;

      .ewm-img {
        width: 124px;
        height: 124px;
        background: url("../assets/img/ewm.png") no-repeat center;
      }
    }
  }

  .right {
    height: 500px;
    display: flex;
    padding-left: 10%;

    .nav-item {
      flex: 1;
      height: 500px;
      position: relative;

      .title {
        font-size: 18px;
        font-weight: bold;
        color: #FFFFFF;
        padding-bottom: 20px;
      }

      li {
        a {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 42px;
        }
      }
    }
  }

  .ysxk-bg {
    width: 38%;
    height: 38%;
    position: absolute;
    right: 10%;
    bottom: 20%;
    background: url("../assets/img/ysxk.png") no-repeat center;
    background-size: contain;
  }
}

.line {
  margin-top: 2%;
  width: 100%;
  height: 1px;
  background-color: #999999;
}

.down-wrap {
  line-height: 40px;

  .one {
    text-align: left;

    span {
      padding-right: 5px;

      a {
        color: #ffffff;
      }
    }
  }

  .tow {
    text-align: center;
  }

  .three {
    text-align: right;

    span {
      margin-left: 10px;

      a {
        color: #ffffff;
      }
    }
  }

  .four {
    line-height: 60px;

    a {
      color: #ffffff;
    }

    .police {
      width: 15px;
      height: 15px;
      display: inline-block;
      background: url("../assets/img/police.png") no-repeat center;
      background-size: 15px;
      margin-left: 5px;
    }
  }
}

.phone-footer-wrap {
  display: none;
  padding: 2% 0;
  font-family: Microsoft YaHei;
  text-align: center;
  background-color: #333333;
  color: #ffffff;

  .max-center {
    padding: 0 5%;

    p {
      font-size: 14px;
      line-height: 2;
      font-weight: 400;

      a {
        color: #ffffff;
        padding: 0 5px;
      }
    }
  }

}

// 媒体查询
@media (max-width: 1300px) {
  .center-wrap {
    width: 80%;
  }
}

@media (max-width: 1200px) {
  .center-wrap .right {
    padding-left: 0;
    padding-top: 5%;
  }

  .down-wrap {
    .tow, .three {
      text-align: left;
    }
  }
}

@media (max-width: 850px) {
  .footer-wrap {
    display: none;
  }

  // 变成手机模式
  .phone-footer-wrap {
    display: block;
  }

}

</style>
