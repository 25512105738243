<template>
  <div>
    <Header is-active="home" :theme="'white'"></Header>
    <Section1></Section1>
    <Section2></Section2>
    <Section3></Section3>
    <Section4></Section4>
    <Section5></Section5>
    <Section6></Section6>
    <BackTop></BackTop>
  </div>
</template>

<script>
import Header from '../../components/Header.vue'
import Section1 from './modules/Section1.vue'
import Section2 from './modules/Section2.vue'
import Section3 from './modules/Section3.vue'
import Section4 from './modules/Section4.vue'
import Section5 from './modules/Section5.vue'
import Section6 from './modules/Section6.vue'
import BackTop from '../../components/BackTop.vue'

export default {
  metaInfo: {
    meta: [
      {
        name: 'keyWords',
        content: '云昇新控官网，云昇新控,智慧水务,智慧工厂,自动化系统集成,智慧照明,重庆云昇新控智能科技有限公司'
      },
      {
        name: 'description',
        content: '云昇新控官网,重庆云昇新控智能科技有限公司'
      }
    ]
  },
  components: {
    Header,
    Section1,
    Section2,
    Section3,
    Section4,
    Section5,
    Section6,
    BackTop
  },
  data () {
    return {}
  },
  methods: {}
}
</script>
<style>

.box-height-wrap {
  height: 800px;
}

@media (min-width: 1024px) {
  .box-height-wrap {
    height: 600px;
  }
}

/*>=1024的设备*/
@media (min-width: 1100px) {
  .box-height-wrap {
    height: 760px;
    background-color: darkblue!important;
  }
}

/*>=1100的设备*/
@media (min-width: 1280px) {
  .box-height-wrap {
    height: 810px;
  }
}

/*>=1280的设备*/
@media (min-width: 1366px) {
  .box-height-wrap {
    height: 840px;
  }
}

@media (min-width: 1440px) {
  .box-height-wrap {
    height: 860px;
  }
}

@media (min-width: 1680px) {
  .box-height-wrap {
    height: 900px;
  }
}

@media (min-width: 1920px) {
  .box-height-wrap {
    height: 970px;
  }
}
</style>
